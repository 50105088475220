@use '../../../styles/style';

.ant-table-tbody > tr.ant-table-row > td {
  @extend .table-cell;


  &.default-cell {
    padding: 0;
    position: relative;
  }

  &.warning-cell {
    background: #ffc2b3;
  }

  &.lack-hours-cell {
    background: #ffe0de;
  }
}

.ant-table-tbody > tr.ant-table-row-level-0 {

  &.background-thead-platforms {
    background: lightgray;

    td.ant-table-column-sort {
      background: lightgray;
    }
  }

  &.background-thead-platforms:hover {
    background: lightgray;
  }

  &.background-row-total {
    background: #fafafa;
    font-weight: 500;
  }
}

.ant-table-tbody > tr.ant-table-row-level-0:hover {

  &.background-thead-platforms > td {
    background: lightgray;
  }
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  cursor: pointer;
}

.editable-row:hover .work-log-time {
  padding: 3px 2ch;
  cursor: pointer;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

.ant-table-summary tr,
.ant-table-summary > tr td div {
  background: #fafafa;
  font-weight: 500;
}

.ant-form-inline .ant-form-item {
  margin-right: 8px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 500px) {
  .section-filters {
    flex-direction: column;

    > div {
      margin-left: inherit;
    }
  }
}

.worklog_table {
  .ant-table-small {
    .ant-table-tbody > tr > td, .ant-table-summary > tr > td, .ant-table-thead > tr > th {
      font-size: 12px;
      padding: 6px 6px;
    }
    .ant-table-tbody > tr > td div {
      line-height: 16px;
    }
    .ant-table-summary > tr > td {
      text-align: center;
    }
  }
}
