.table-cell {
  &.vacation {
    color: #000000;
    background: #ccffdd;
  }

  &.sick {
    background: #00bfff;
  }

  &.day-off {
    color: #000000;
    background: #e6ffe6;
  }

  &.vacation-log {
    &.vacation {
      color: #000000;
      background: #beedce;
    }

    &.sick {
      color: #FFFFFF;
      background: #0099cc;
    }

    &.day-off {
      color: #000000;
      background: #d5edd5;
    }

    &.absent {
      background: #FFC0CB;
    }

    &.missed {
      background: #f5222d;
    }
  }
}

.rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
  @extend .table-cell;
}

.rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event .disable-select {
  user-select: none;
}

.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
  padding: 0 0 3px;
}

.table-cell {
  &.interview-developer {
    color: #000000;
    background: #ffa39e;
  }

  &.interview-qa {
    color: #000000;
    background: #ffe58f;
  }

  &.interview-pm {
    color: #000000;
    background: #8cc8ff;
  }

  &.interview-hr {
    color: #FFFFFF;
    background: #0099cc;
  }

  &.interview-om {
    color: #FFFFFF;
    background: #0099cc;
  }

  &.interview-designer {
    color: #000000;
    background: #6ac174;
  }

}